<template>
    <div
        class="cart-icon-main position-relative"
        :class="cartEffectClass"
    >
        <v-badge
            v-if="Number.isInteger(cartItemCount) && cartItemCount > 0"
            :content="cartItemCount"
            density="compact"
            color="primary"
            offset-x="-3"
            offset-y="-3"
        >
            <v-icon
                :icon="mdiCart"
                :size="size"
            ></v-icon>
        </v-badge>
        <v-icon
            v-else
            :icon="mdiCartOutline"
            :size="size"
        ></v-icon>
        <client-only>
            <v-progress-circular
                v-if="isCartBusy"
                class="cart-loading-status"
                color="secondary"
                size="20"
                width="2"
                indeterminate
            ></v-progress-circular>
        </client-only>
    </div>
</template>

<script lang="ts" setup>
    import { mdiCart, mdiCartOutline } from '@mdi/js';
    import { cartItemCount, isCartBusy } from '~/composables';

    withDefaults(
        defineProps<{
            size?: Undefinable<number | string>;
        }>(),
        {
            size: undefined
        }
    );

    const onEffectName = 'shockwave-on';
    const cartEffectClass = ref<string>('');
    let timeout: Undefinable<ReturnType<typeof setTimeout>>;

    const unWatchCartCount = watch(
        cartItemCount,
        (count: Nilish<number>) => {
            if (Number.isInteger(count)) {
                cartEffectClass.value = onEffectName;
                clearTimeout(timeout);
                timeout = setTimeout(() => {
                    cartEffectClass.value = '';
                }, 2000);
            }
        },
        {
            immediate: true
        }
    );

    onBeforeUnmount(() => {
        clearTimeout(timeout);
        unWatchCartCount();
    });
</script>

<style lang="scss">
.cart-icon-main {
    .v-progress-circular {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 1;
        filter: invert(0.5) blur(1px);
    }
}
</style>
